import getURL from "api/config";
import { POST, PUT, GET } from "api/index";
import * as types from "constants/actionTypes";
import { getError } from "helpers";
import { MULTIPART_HEADERS } from "constants/http";

export function getUserListAction(payload) {
    return function action(dispatch) {
        const url = getURL("GET_USER_LIST");
        const request = POST(url, payload);
    
        dispatch({
            type: types.USER_LIST_LOAD_REQUEST,
            payload: { users: [], isLoading: true, total: 0 }
        })

        request.then((response) => {
            const users = response?.data?.result.data || [];
            const total = response?.data?.result.total || 0;
            const lastPage = response?.data?.result.last_page || 0;
            const currentPage = response?.data?.result.current_page || 0;
            dispatch({
                isLoading: false,
                type: types.USER_LIST_LOAD_SUCCESS,
                payload: { users, total, lastPage, currentPage }
            })
        }, (error) => {
            dispatch({
                isLoading: false,
                type: types.USER_LIST_LOAD_FAILED,
                payload: {
                    errors: getError(error)
                }
            });
        });   
    };
};

export function addUserAction(payload) {
    return function action(dispatch) {
        const url = getURL("INSERT_USER");
        const request = POST(url, payload);

        request.then((response) => {
            const user = response?.data?.result.data || {};
            dispatch({
                type: types.ADD_USER_SUCCESS,
                payload: { user }
            })
        }, (error) => {
            dispatch({
                type: types.ADD_USER_FAILED,
                payload: {
                    errors: getError(error)
                }
            })
        })
    }
};

export const updateUserAction = (id, payload) => async(dispatch) => {
    try {
        const url = getURL("UPDATE_USER")(id);
        const response = await PUT(url, payload);
        const user = response?.data?.result.data || {};

        dispatch({
            type: types.UPDATE_USER_SUCCESS,
            payload: { user }
        })
    } catch(error) {
        dispatch({
            type: types.UPDATE_USER_FAILED,
            payload: {
                errors: getError(error)
            }
        })
    }
};

export function clearUserErrorsAction() {
    return function action(dispatch) {
        dispatch({
            type: types.CLEAR_USER_ERRORS
        })
    };
};

export function uploadUsersAction(data) {
    return function action(dispatch) {
        const url = getURL("UPLOAD_USERS");
        const request = POST(url, data, MULTIPART_HEADERS);

        dispatch({
            type: types.UPLOAD_USERS_REQUEST,
            payload: { isLoading: true, uploadError: false }
        })

        request.then((response) => {
            dispatch({
                type: types.UPLOAD_USERS_SUCCESS,
                payload: { users: response.data.result, isLoading: false, uploadError: false }
            })
        }, (error) => {
            dispatch({
                type: types.UPLOAD_USERS_FAILED,
                payload: { errors: getError(error), isLoading: false, uploadError: true }
            })
        });
    };
};

export function getUserAction(id) {
    return function action(dispatch) {
        const url = getURL("GET_USER")(id);
        const request = GET(url);

        request.then((response) => {
            const user = response?.data?.result || {};
            dispatch({
                type: types.GET_USER_SUCCESS,
                payload: { user }
            })
        }, (error) => {
            dispatch({
                type: types.GET_USER_FAILED,
                payload: { errors: getError(error) }
            })
        })
    }
}   