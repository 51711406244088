import './App.css';
import Analyze from './pages/Analyze/Analyze';
import Landing from './pages/Landing/Landing';
import { Route, Routes } from 'react-router-dom';
import ManageVoters from './pages/Manage/ManageVoters/ManageVoters';
import ManageVariables from './pages/Manage/ManageVariables/ManageVariables';
import ManageUsers from './pages/Manage/ManageUsers/ManageUsers';
import RequestForDemo from './pages/RequestForDemo/RequestForDemo';
import Header from './components/Header/Header';
import PublicLanding from './pages/PublicLanding/PublicLanding';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ManageRoles from './pages/Manage/ManageRoles/ManageRoles';
import Gather from 'pages/Gather/Gather';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<PublicLanding />}>
          <Route path="cms" element={<Landing />}>
            <Route path="analyze" element={<Analyze />} />
            <Route path="gather" element={<Gather />} />
            <Route path="manage">
              <Route path="variables" element={<ManageVariables />} />
              <Route path="voters" element={<ManageVoters />} />
              <Route path="users" element={<ManageUsers />} />
              <Route path="roles" element={<ManageRoles />} />
            </Route>          
          </Route>          
          <Route path="login" element={<Login />} />
          <Route path="request-for-demo" element={<RequestForDemo />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route>        
      </Routes>
    </div>
  );
}

export default App;
