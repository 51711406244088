import "./ManageUsers.css";
import { Button, Card, Container, Col, Row, Stack, Table, Badge, Spinner } from "react-bootstrap";
import { useEffect, useState, useCallback } from "react";
import UserModal from "../../../components/Modals/UserModal/UserModal.jsx";
import { getUserListAction } from "actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "selectors/userSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faRotateLeft, faPencil } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "helpers";
import { getVariableListAction } from "actions/variableActions";
import { useRef } from "react";
import { faFileImport, faFilter } from "@fortawesome/free-solid-svg-icons";
import FilterUsers from "./FilterUsers/FilterUsers";
import UserUploadMapper from "./UserUploadMapper/UserUploadMapper";

function ManageUsers() {
    const { users, total, lastPage, currentPage, isLoading } = useSelector(userSelector);
    const [ userList, setUserList ] = useState([]);
    const [ page, setPage ] = useState(1);
    const cardRef = useRef(null);
    const newUser = {
        user_id: "",
        name: "",
        username: "",
        sex: "",
        birthdate: "",
        contact_no: "",
        email: ""
    };
    const [ showUserModal, setShowUserModal ] = useState({ show: false, user: {} });
    const [ showFilterUsers, setShowFilterUsers ] = useState({ show: false, user: {} });
    const [ showUploadMapper, setShowUploadMapper ] = useState(false);
    const dispatch = useDispatch();
    
    const fetchData = useCallback(() => {
        if (page < lastPage) {
            setPage(page + 1);
        };
    }, [page, lastPage]);

    const handleUserModalClose = () => {
        setShowUserModal({ show: false, user: {} });
        setUserList([]);
        dispatch(getUserListAction());
    };

    const handleAddUser = () => {
        setShowUserModal({ show: true, user: newUser });
    };

    const handleResetUserList = () => {
        setUserList([]);
        setPage(0);
        fetchData();
    };

    const handleImportUsers = () => {
        setShowUploadMapper(true);
    };

    const handleFilterUsers = () => {
        setShowFilterUsers({ show: true, user: {} });
    };

    const handleFilterUsersClose = (filters) => {
        setUserList([]);
        dispatch(getUserListAction(filters));
        setShowFilterUsers({ show: false, user: {} });
    };

    const handleScroll = useCallback(() => {
        if (cardRef.current.scrollHeight > (cardRef.current.offsetHeight + cardRef.current.scrollTop) || isLoading) return;
        fetchData();
    }, [isLoading, fetchData]);

    useEffect(() => {
        setUserList([]);
        setPage(0);
        dispatch(getVariableListAction());
    }, [dispatch]);

    useEffect(() => {
        if (page > 0) {
            dispatch(getUserListAction({ page }));
        }
    }, [dispatch, page]);

    useEffect(() => {
        cardRef.current.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading, handleScroll]);

    useEffect(() => {
        if (users) {
            setUserList((prev) => [...prev, ...users]);
        }
    }, [users]);

    return(
        <section id="manageUsers">
            { showUserModal.show && <UserModal show={showUserModal.show} onHide={handleUserModalClose} user={showUserModal.user} /> }
            { showFilterUsers.show && <FilterUsers show={showFilterUsers.show} onHide={handleFilterUsersClose} /> }
            { showUploadMapper && <UserUploadMapper show={showUploadMapper} onHide={() => setShowUploadMapper(false)} /> }
            <Container>
                <Row>
                    <Col lg={12}>
                        <Card className="view">
                            <Card.Header>
                                <Stack direction="horizontal" className="d-flex justify-content-between">
                                    <div>
                                        <span>Manage Users</span>{" "}
                                        <Badge bg="success" size="sm">{userList.length}/{total}</Badge>
                                    </div>
                                    <div>
                                        <Button variant="success" size="sm" onClick={handleImportUsers}><FontAwesomeIcon icon={faFileImport} /></Button>{" "}
                                        <Button variant="outline-success" size="sm" onClick={handleAddUser}><FontAwesomeIcon icon={faPlus} /></Button>{" "}
                                        <Button variant="primary" size="sm" onClick={handleFilterUsers}><FontAwesomeIcon icon={faFilter} /></Button>{" "}
                                        <Button variant="outline-primary" size="sm" onClick={handleResetUserList}><FontAwesomeIcon icon={faRotateLeft} /></Button>
                                    </div>
                                </Stack>
                            </Card.Header>
                            <Card.Body className="wrapper" ref={cardRef}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Name</th>
                                            <th>Username</th>
                                            <th>Role</th>
                                            <th>Sex</th>
                                            <th>Birthdate</th>
                                            <th>Assigned Province</th>
                                            <th>Assigned Municipality/City</th>
                                            <th>Assigned Barangay</th>
                                            <th>Assigned Precinct</th>                                                                                
                                            <th>Enabled</th>       
                                            <th>Action</th>                             
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            userList.map((user, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{user.name}</td>
                                                        <td>{user.username}</td>
                                                        <td>{user.role_name}</td>
                                                        <td>{user.sex}</td>
                                                        <td>{formatDate(user.birthdate)}</td>                                                                
                                                        <td>{user.province || 'ALL'}</td>
                                                        <td>{user.municity || 'ALL'}</td>
                                                        <td>{user.barangay || 'ALL'}</td>
                                                        <td>{user.precinct || 'ALL'}</td>                                                        
                                                        <td>{user.is_active ? 'Yes' : 'No'}</td>                                                        
                                                        <td className="sticky-col first-col">
                                                            <Button variant="primary" size="sm" onClick={() => setShowUserModal({ show: true, user: user })}><FontAwesomeIcon icon={faPencil} /></Button>
                                                        </td>
                                                    </tr>                                                
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                                <div className="d-flex justify-content-center">
                                    {isLoading && <Spinner animation="border" role="status" />}
                                    {!isLoading && userList.length === 0 && <span>No users found.</span>}
                                    {!isLoading && currentPage === lastPage && <span>End of List</span>}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>                
            </Container>
        </section>
    )
}

export default ManageUsers;