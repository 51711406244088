function CustomLabel(props) {
    const { x, y, payload } = props;
    
    return(
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} textAnchor="middle" fill="#666" transform={`rotate(-90)`}>
                {payload.value}
            </text>
        </g>
    )
}

export default CustomLabel;