import { Button, Card, Col, Form, FormCheck, Row, Stack } from "react-bootstrap";
import "./Analyze.css";
import { Bar, BarChart, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import CustomLabel from "../../components/Graph/CustomLabel";
import { useState } from "react";

function Analyze() {
    const [ mode, setMode ] = useState('bar')
    const onToggle = () => {
        if (mode === 'bar') {
            setMode('line')
        } else {
            setMode('bar')
        }
    }
    const data = [
        {
            "name": "Dimagiba",
            "count": 20
        },
        {
            "name": "Mandigma",
            "count": 30
        },
        {
            "name": "Others",
            "count": 28
        }
    ]

    return(
        <section id="analyze">
            <Row>
                <Col lg={9}>
                    <Card className="analyze-graphs">
                        <Card.Header>
                            <Stack direction="horizontal" className="d-flex justify-content-between">
                                <span>Data Visualization</span>
                                <FormCheck type="switch" label={mode === 'bar' ? 'Bar Graph' : 'Line Graph'} as="input" checked={mode === 'bar'} onChange={() => onToggle()}/>
                            </Stack>
                        </Card.Header>
                        <Card.Body>
                            {
                                mode === 'bar' ? 
                                <ResponsiveContainer width="100%" height={400}>
                                <BarChart data={data} layout="vertical">
                                    <YAxis type="category" dataKey="name" tick={<CustomLabel />}/>
                                    <XAxis type="number" />
                                    <Tooltip />
                                    <Bar dataKey="count" fill="#8884d8">
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer> :
                            <ResponsiveContainer>
                                <LineChart data={data}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Line dataKey="count" fill="#8884d8"></Line>
                                </LineChart>
                            </ResponsiveContainer>                    
                            }
                            
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3}>
                    <Card className="analyze-filters">
                        <Card.Header>Filters</Card.Header>
                        <Card.Body>
                            <Form>
                                <Stack gap={3}>
                                    <Form.Group>
                                        <Form.Label>Select Variable</Form.Label>
                                        <Form.Select>
                                            <option>Mayor Preference</option>
                                            <option>Governor Preference</option>
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Unit Level</Form.Label>
                                        <Form.Select>
                                            <option>National</option>
                                            <option>Regional</option>
                                            <option>City/Municipal</option>
                                            <option>Barangay</option>
                                            <option>Gender</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Stack>                            
                            </Form>
                        </Card.Body>
                        <Card.Footer>
                            <Button className="w-100">Export</Button>                        
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>            
        </section>
    )
}

export default Analyze;