import getURL from "api/config";
import { PUT, POST } from "api/index";
import * as types from "constants/actionTypes";
import { getError } from "helpers";
import { MULTIPART_HEADERS } from "constants/http";

export function getVoterListAction(data) {
    return function action (dispatch) {
        const url = getURL("GET_VOTER_LIST");
        const request = POST(url, data);

        dispatch({
            isLoading: true,
            type: types.VOTER_LIST_LOAD_REQUEST,
            payload: { voters: [], total: 0 }
        })
    
        request.then((response) => {
            const voters = response?.data?.result?.data?.data || {};
            const total = response?.data?.result?.data?.total || 0;
            const lastPage = response?.data?.result?.data?.last_page || 0;
            const currentPage = response?.data?.result?.data?.current_page || 0;
            dispatch({
                isLoading: false,
                type: types.VOTER_LIST_LOAD_SUCCESS,
                payload: { voters, total, lastPage, currentPage }
            })
        }, (error) => {
            dispatch({
                type: types.VOTER_LIST_LOAD_FAILED,
                payload: {
                    isLoading: false,
                    errors: getError(error)
                }
            })
        });
    };        
};

export function addVoterAction(data) {
    return function action(dispatch) {
        const url = getURL("INSERT_VOTER");
        const request = POST(url, data);

        request.then((response) => {
            dispatch({
                type: types.ADD_VOTER_SUCCESS,
                payload: { voter: response.data.result }
            })
        }, () => {
            dispatch({
                type: types.ADD_VOTER_FAILED,
                payload: { errors: 'Failed to add voter' }
            })
        });
    };
}

export function updateVoterAction(id, data) {
    return function action(dispatch) {
        const url = getURL("UPDATE_VOTER")(id);
        const request = PUT(url, data);

        dispatch({
            type: types.UPDATE_VOTER_REQUEST
        })

        request.then((response) => {
            dispatch({
                type: types.UPDATE_VOTER_SUCCESS,
                payload: { voter: response?.data?.result }
            })
        }, () => {
            dispatch({
                type: types.UPDATE_VOTER_FAILED,
                payload: { errors: 'Failed to update voter' }
            }) 
        });
    };
}

export function clearVoterErrorsAction() {
    return function action(dispatch) {
        dispatch({
            type: types.CLEAR_VOTER_ERRORS
        })
    };
};

export function uploadVotersAction(data) {
    return function action(dispatch) {
        const url = getURL("UPLOAD_VOTERS");
        const request = POST(url, data, MULTIPART_HEADERS);

        dispatch({
            type: types.UPLOAD_VOTERS_REQUEST,
            payload: { isLoading: true, uploadError: false }
        })

        request.then((response) => {
            dispatch({
                type: types.UPLOAD_VOTERS_SUCCESS,
                payload: { voters: response.data.result, isLoading: false, uploadError: false }
            })
        }, (error) => {
            dispatch({
                type: types.UPLOAD_VOTERS_FAILED,
                payload: { errors: getError(error), isLoading: false, uploadError: true }
            })
        });
    };
};
