import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Stack, Spinner, Container } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { addVoterAction, updateVoterAction } from "actions/voterActions.js";
import { variableSelector } from "selectors/variableSelector";
import { getVariableListAction } from "actions/variableActions";
import { formatDate } from "helpers";
import { v4 as uuidv4 } from "uuid";
import { provinceSelector } from "selectors/provinceSelector";
import { getProvinceListAction } from "actions/provinceActions";
import { getMunicipalityListAction } from "actions/municipalityActions";
import { municipalitySelector } from "selectors/municipalitySelector";
import { precinctSelector } from "selectors/precinctSelector";
import { getPrecinctListAction } from "actions/precinctActions";
import { barangaySelector } from "selectors/barangaySelector";
import { getBarangayListAction } from "actions/barangayActions";
import { voterSelector } from "selectors/voterSelector";

function VoterModal(props) {
    const { onHide, voter, show } = props;
    const dispatch = useDispatch();
    const { variables } = useSelector(variableSelector);
    const { provinces } = useSelector(provinceSelector);
    const { municipalities } = useSelector(municipalitySelector);
    const { precincts } = useSelector(precinctSelector);
    const { barangays } = useSelector(barangaySelector);
    const { isLoading } = useSelector(voterSelector);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({...voter, voter_responses: JSON.parse(voter.voter_responses[0]?.response || '{}')});
    const [answers, setAnswers] = useState(voter.voter_responses || []);

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    };

    const handleVoterResponseChange = (e) => {
        console.log('changed')
        setFormData((prev) => ({
            ...prev,
            voter_responses: {
                ...prev.voter_responses,
                [e.target.name]: e.target.value
            }
        }));

        setAnswers((prev) => {
            console.log([{
                response: JSON.stringify({
                    ...JSON.parse(prev[0]?.response || '{}'),
                    [e.target.name]: e.target.value
                }),
                ...prev
            }])
            return[{
            response: JSON.stringify({
                ...JSON.parse(prev[0]?.response || '{}'),
                [e.target.name]: e.target.value
            }),
            ...prev
        }]});        
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setValidated(true);
            return;
        }
        if (voter.voter_id) {
            dispatch(updateVoterAction(voter.voter_id, formData));
        } else {
            dispatch(addVoterAction(formData));
        }

        while (isLoading) {
            await new Promise(resolve => setTimeout(resolve, 2000));
        }

        handleClose();
    };

    const getResponse = (variable) => {
        return JSON.parse(answers[0]?.response || '{}')?.[variable.name];
    }

    const getInputType = (variable) => {           
        if (variable.type === 'ARRAY') {
            return <Form.Select name={variable.name} onChange={(e) => handleVoterResponseChange(e)} value={getResponse(variable) || ''}>
                <option value="">Select {variable.name}</option>
                {variable.variable_options.map((option) => (
                    <option key={uuidv4()} value={option.name}>{option.name}</option>
                ))}
            </Form.Select>
        } else {
            return <Form.Control name={variable.name} onBlur={(e) => handleVoterResponseChange(e, true)} defaultValue={getResponse(variable) || ''} placeholder={`Enter ${variable.name}`}/>                
        }
    }

    const handleClose = () => {
        onHide();
    }

    useEffect(() => {
        dispatch(getVariableListAction());
        dispatch(getProvinceListAction());
        dispatch(getMunicipalityListAction());
        dispatch(getPrecinctListAction());
        dispatch(getBarangayListAction());
    }, [dispatch]);

    return(
        <Modal onHide={handleClose} show={show} size="lg" animation>
            <Modal.Header closeButton>Voter Profile</Modal.Header>
            <Modal.Body>
                <Container>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Stack gap={2} className="mb-3">
                            <Row>
                                <Col lg={3}><Form.Label>Full Name</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Control 
                                        name="name" 
                                        onChange={handleChange} 
                                        defaultValue={formData.name} 
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a name.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}><Form.Label>Address</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Control 
                                        name="address" 
                                        onChange={handleChange} 
                                        defaultValue={formData.address} 
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide an address.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}><Form.Label>Sex</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Select
                                        id="sex"
                                        name="sex"
                                        onChange={handleChange}
                                        value={formData.sex}
                                        required
                                    >
                                        <option value="">Select sex</option>
                                        {['M', 'F'].map((value) => (
                                            <option key={uuidv4()} value={value}>
                                                {value === 'M' ? 'Male' : 'Female'}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a sex.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}><Form.Label>Birthdate</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Control 
                                        id="birthdate"
                                        name="birthdate" 
                                        type="date" 
                                        onChange={handleChange} 
                                        defaultValue={formatDate(formData.birthdate)} 
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a birthdate.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}><Form.Label>Province</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Select
                                        name="province"
                                        onChange={handleChange}
                                        value={formData.province}
                                        required
                                    >
                                        <option value="">Select province</option>
                                        {provinces.map((province) => (
                                            <option key={uuidv4()} value={province}>
                                                {province}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a province.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}><Form.Label>Municipality</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Select
                                        name="municipality"
                                        onChange={handleChange}
                                        value={formData.municity}
                                        required
                                    >
                                        <option value="">Select municipality</option>
                                        {municipalities.map((municipality) => (
                                            <option key={uuidv4()} value={municipality}>
                                                {municipality}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a municipality.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3}><Form.Label>Barangay</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Select
                                        name="barangay"
                                        onChange={handleChange}
                                        value={formData.barangay}
                                        required
                                    >
                                        <option value="">Select barangay</option>
                                        {
                                            barangays.map((barangay) => (
                                                <option key={uuidv4()} value={barangay}>{barangay}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a barangay.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={3}><Form.Label>Precinct</Form.Label></Col>
                                <Col lg={9}>
                                    <Form.Select
                                        name="precinct"
                                        onChange={handleChange}
                                        value={formData.precinct}
                                        required
                                    >
                                        <option value="">Select precinct</option>
                                        {
                                            precincts.map((precinct) => (
                                                <option key={uuidv4()} value={precinct}>{precinct}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a precinct.
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                            {
                                variables.map((variable) => (
                                    <Row key={uuidv4()}>
                                        <Col lg={3}><Form.Label>{variable.name}</Form.Label></Col>
                                        <Col lg={9}>{getInputType(variable)}</Col>
                                    </Row>
                                ))
                            }
                        </Stack>
                        <Modal.Footer>
                            <Button variant="success" type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        <span>Saving...</span>
                                    </>
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default VoterModal