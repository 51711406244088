import { Button, Card, Col, Container, Row, Table, Stack } from "react-bootstrap";
import "./Gather.css";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVoterListAction } from "actions/voterActions";
import { voterSelector } from "selectors/voterSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import GatherForm from "./GatherForm/GatherForm";
import { sessionSelector } from "selectors/sessionSelector";

function Gather() {
    const { user } = useSelector(sessionSelector);
    const { voters } = useSelector(voterSelector);
    const [voterList, setVoterList] = useState([]);
    const [selectedVoter, setSelectedVoter] = useState(null);
    const dispatch = useDispatch();

    const removeBlankFilters = useCallback(() => {
        const filters = {
            province: user.province,
            municity: user.municity,
            barangay: user.barangay,
            precinct: user.precinct
        };
        return Object.fromEntries(Object.entries(filters).filter(([key, value]) => (value !== null)));
    }, [user]);

    const handleReset = () => {
        setSelectedVoter(null);
        setVoterList([]);
        dispatch(getVoterListAction(removeBlankFilters()));
    }

    const handleSubmit = () => {
        setSelectedVoter(null);
        handleReset();
    }

    const handleDiscard = () => {
        setSelectedVoter(null);
    }

    useEffect(() => {
        setVoterList([]);
    }, []);

    useEffect(() => {
        dispatch(getVoterListAction(removeBlankFilters()));
    }, [dispatch, removeBlankFilters]);

    useEffect(() => {
        if (voters.length > 0) {
            setVoterList(voters);
        }
    }, [voters]);

    return(
        <section id="gather">
            <Container>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header><Stack direction="horizontal" gap={2} className="d-flex justify-content-between">
                                <span>Find Voter</span>
                                <div>
                                    <Button variant="primary" size="sm"><FontAwesomeIcon icon={faFilter} /></Button>{" "}
                                    <Button variant="outline-primary" size="sm"><FontAwesomeIcon icon={faRotateLeft} onClick={handleReset} /></Button>
                                </div>
                            </Stack></Card.Header>
                            <Card.Body>
                                {selectedVoter ? <GatherForm voter={selectedVoter} onSubmit={handleSubmit} onDiscard={handleDiscard} /> : <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            voterList.map((voter, index) => (
                                                <tr key={index} onClick={() => setSelectedVoter(voter)}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <Stack direction="vertical">
                                                            <span><h4>{voter.name}</h4></span>
                                                            <span><h5>{voter.precinct}</h5></span>
                                                            <span>{voter.address}</span>
                                                            <span>{voter.province}, {voter.municity}, {voter.barangay}</span>
                                                        </Stack>
                                                    </td>                                                       
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Gather;