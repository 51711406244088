import "./FilterVoters.css";
import { Button, Modal, Container, Form, Stack } from "react-bootstrap";
import { useState, useEffect } from "react";

function FilterVoters({show, onHide}) {
    const [filters, setFilters] = useState({});
    const [options, setOptions] = useState({
        sex: [],
        province: [],
        municity: [],
        barangay: [],
        precinct: []
    });
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    useEffect(() => {
        setOptions({
            sex: [],
            province: [],
            municity: [],
            barangay: [],
            precinct: []
        })
    }, []);

    return (
        <Modal id="filterVoters" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Filter Voters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Container>
                    <Stack gap={2}>
                        <Form.Group>
                            <Form.Label>
                                Name
                            </Form.Label>
                            <Form.Control type="text" name="name" placeholder="Name" onChange={handleFilterChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Address
                            </Form.Label>
                            <Form.Control type="text" name="address" placeholder="Address" onChange={handleFilterChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Sex
                            </Form.Label>
                            <Form.Select name="sex"onChange={handleFilterChange}>
                                {
                                    options.sex.map((option) => (
                                        <option value={option}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Birthdate
                            </Form.Label>
                            <Form.Control name="birthdate" type="date" onChange={handleFilterChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Province
                            </Form.Label>
                            <Form.Select name="province" onChange={handleFilterChange}>
                                {
                                    options.province.map((option) => (
                                        <option value={option}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Municipality/City
                            </Form.Label>
                            <Form.Select name="municity" onChange={handleFilterChange}>
                                {
                                    options.municity.map((option) => (
                                        <option value={option}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Barangay
                            </Form.Label>
                            <Form.Select name="barangay" onChange={handleFilterChange}>
                                {
                                    options.barangay.map((option) => (
                                        <option value={option}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Precinct
                            </Form.Label>
                            <Form.Select name="precinct" onChange={handleFilterChange}>
                                {
                                    options.precinct.map((option) => (
                                        <option value={option}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                    </Stack>                    
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => onHide(filters)}>Filter</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FilterVoters;