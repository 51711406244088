import "./ManageVoters.css";
import { Button, Card, Container, Row, Col, Table, Spinner, Badge, Stack } from "react-bootstrap";
import { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport, faFilter, faPlus, faRotateLeft, faPencil } from "@fortawesome/free-solid-svg-icons";
import { getVoterListAction } from "actions/voterActions";
import { getVariableListAction } from "actions/variableActions";
import { variableSelector } from "selectors/variableSelector";
import { voterSelector } from "selectors/voterSelector";
import { formatDate } from "helpers/index";
import VoterUploadMapper from "./VoterUploadMapper/VoterUploadMapper";
import FilterVoters from "./FilterVoters/FilterVoters";
import VoterModal from "components/Modals/VoterModal/VoterModal";
import { v4 as uuidv4 } from "uuid";

function ManageVoters() {
    const [page, setPage] = useState(0);
    const [showFilterVoters, setShowFilterVoters] = useState(false);
    const [showUploadMapper, setShowUploadMapper] = useState(false);
    const [showVoterModal, setShowVoterModal] = useState({ voter: null, show: false });
    const [voterList, setVoterList] = useState([]);
    const { voters, isLoading, currentPage, lastPage, total } = useSelector(voterSelector);
    const { variables } = useSelector(variableSelector);
    const cardRef = useRef(null);
    const dispatch = useDispatch();

    const getVariableData = (variable, voter) => {
        return JSON.parse(voter.voter_responses[0]?.response || '{}')?.[variable.name] || '';       
    }

    const fetchData = useCallback(() => {
        if (page < lastPage) {
            setPage(page + 1);
        };
    }, [page, lastPage]);

    const handleFilter = (filters) => {
        setVoterList([]);
        dispatch(getVoterListAction(filters));
        setShowFilterVoters(false);
    }

    const handleFilterVoters = () => {
        setShowFilterVoters(true);
    };

    const handleImportVoters = () => {
        setShowUploadMapper(true);
    };

    const handleShowVoterModal = (voter) => {
        setShowVoterModal({ voter, show: true });
    }

    const handleReset = () => {
        setVoterList([]);
        setPage(0);
        fetchData();
    }

    const handleScroll = useCallback(() => {
        if (cardRef.current.scrollHeight > (cardRef.current.offsetHeight + cardRef.current.scrollTop) || isLoading) return;
        fetchData();
    }, [isLoading, fetchData]);

    const handleVoterModalClose = () => {
        handleReset();
        setShowVoterModal({ voter: null, show: false });        
    }

    useEffect(() => {
        setPage(1);
        dispatch(getVariableListAction());
    }, [dispatch]);

    useEffect(() => {
        if (page > 0) {
            dispatch(getVoterListAction({ page }));
        }
    }, [dispatch, page]);

    useEffect(() => {
        cardRef.current.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isLoading, handleScroll]);

    useEffect(() => {
        if (voters) {
            setVoterList((prev) => [...prev, ...voters]);
        }
    }, [voters]);

    return (
        <section id="manageVoters">
            <Container>
                <FilterVoters show={showFilterVoters} onHide={handleFilter} />
                <VoterUploadMapper show={showUploadMapper} onHide={() => setShowUploadMapper(false)} />
                { showVoterModal.voter && <VoterModal show={showVoterModal.show} voter={showVoterModal.voter} onHide={handleVoterModalClose} /> }
                <Row>
                    <Col>
                        <Card className="view">
                            <Card.Header>
                                <Stack className="d-flex justify-content-between" direction="horizontal" gap={2}>
                                    <div>
                                        <span>Manage Voters</span>{" "}
                                        <Badge bg="success" size="sm">{voterList.length}/{total}</Badge>
                                    </div>
                                    <div>
                                        <Button variant="success" size="sm" onClick={handleImportVoters}><FontAwesomeIcon icon={faFileImport} /></Button>{" "}
                                        <Button variant="outline-success" size="sm"><FontAwesomeIcon icon={faPlus} /></Button>{" "}
                                        <Button variant="primary" size="sm" onClick={handleFilterVoters}><FontAwesomeIcon icon={faFilter} /></Button>{" "}
                                        <Button variant="outline-primary" size="sm" onClick={handleReset}><FontAwesomeIcon icon={faRotateLeft} /></Button>
                                    </div>
                                </Stack>
                            </Card.Header>
                            <Card.Body className="wrapper" ref={cardRef}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Name</th>
                                            <th>Sex</th>
                                            <th>Address</th>
                                            <th>Birthdate</th>
                                            <th>Province</th>
                                            <th>Municipality/City</th>
                                            <th>Barangay</th>
                                            <th>Precinct</th>
                                            {
                                                variables.map((variable) => (
                                                    <th key={uuidv4()}>{variable.name}</th>
                                                ))
                                            }
                                            <th className="sticky-col first-col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            voterList.map((voter, index) => (
                                                <tr key={voter.voter_id}>                                                    
                                                    <td>{index + 1}</td>
                                                    <td>{voter.name}</td>
                                                    <td>{voter.sex}</td>
                                                    <td>{voter.address}</td>
                                                    <td>{formatDate(voter.birthdate)}</td>
                                                    <td>{voter.province}</td>
                                                    <td>{voter.municity}</td>
                                                    <td>{voter.barangay}</td>
                                                    <td>{voter.precinct}</td>
                                                    {
                                                        variables.map((variable) => (
                                                            <td key={uuidv4()}>{getVariableData(variable, voter)}</td>
                                                        ))
                                                    }
                                                    <td className="sticky-col first-col">
                                                        <Button size="sm" onClick={() => handleShowVoterModal(voter)}><FontAwesomeIcon icon={faPencil} /></Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                                <Stack gap={2} className="d-flex justify-content-center text-center">
                                    {isLoading && <Spinner animation="border" role="status" />}
                                    {!isLoading && voterList.length === 0 && <span>No voters found.</span>}
                                    {!isLoading && voterList.length > 0 && currentPage === lastPage && <span>End of List</span>}
                                </Stack>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ManageVoters;