import { CLEAR_VOTER_ERRORS, UPLOAD_VOTERS_FAILED, UPLOAD_VOTERS_SUCCESS, ADD_VOTER_FAILED, ADD_VOTER_SUCCESS, CLEAR_ERRORS, DELETE_VOTER, SHOW_VOTER, VOTER_LIST_LOAD_FAILED, VOTER_LIST_LOAD_SUCCESS, UPDATE_VOTER_FAILED, UPDATE_VOTER_SUCCESS, FILTER_VOTER_LIST_FAILED, FILTER_VOTER_LIST_SUCCESS, VOTER_LIST_LOAD_REQUEST, UPLOAD_VOTERS_REQUEST, UPDATE_VOTER_REQUEST } from "constants/actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
    voters: [],
    total: 0,
    voter: null,
    errors: null,
    isLoading: false,
    currentPage: 0,
    lastPage: 0,
    uploadError: false
})

export default function reduce (state = initialState, action) {
    switch (action.type) {
        case VOTER_LIST_LOAD_SUCCESS:
            return {
                ...state,
                voters: action.payload.voters,
                total: action.payload.total,
                error: null,
                isLoading: false,
                lastPage: action.payload.lastPage,
                currentPage: action.payload.currentPage
            };
        case VOTER_LIST_LOAD_FAILED:
            return {
                ...state,
                voters: [],
                total: 0,
                error: action.payload.errors,
                isLoading: false
            }
        case ADD_VOTER_SUCCESS:
            return {
                ...state,
                voter: action.payload.voter,
                error: null
            };
        case ADD_VOTER_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };
        case SHOW_VOTER:
            return {
                ...state,
                voter: action.payload.voter,
                error: action.error ? action.payload.errors : null
            };
        case UPDATE_VOTER_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case UPDATE_VOTER_SUCCESS:
            return {
                ...state,
                voter: action.payload.voter,
                isLoading: false
            };
        case UPDATE_VOTER_FAILED:
            return {
                ...state,
                error: action.payload.errors,
                isLoading: false
            };
        case DELETE_VOTER:
            return {
                ...state,
                voter: action.payload.voter,
                error: action.error ? action.payload.errors : null
            };
        case UPLOAD_VOTERS_SUCCESS:
            return {
                ...state,
                uploadError: action.payload.uploadError,
                isLoading: action.payload.isLoading
            };
        case UPLOAD_VOTERS_FAILED:
            return {
                ...state,
                uploadError: action.payload.uploadError,
                isLoading: action.payload.isLoading
            };  
        case UPLOAD_VOTERS_REQUEST:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                uploadError: action.payload.uploadError
            };
        case CLEAR_VOTER_ERRORS:
            return {
                ...state,
                error: null
            };
        case FILTER_VOTER_LIST_SUCCESS:
            return {
                ...state,
                voters: action.payload.voters,
                total: action.payload.total,
                error: null,
            };
        case FILTER_VOTER_LIST_FAILED:
            return {
                ...state,
                error: action.payload.errors,
                isLoading: false
            };
        case VOTER_LIST_LOAD_REQUEST:
            return {
                ...state,
                isLoading: action.isLoading,
                voters: action.payload.voters,
                total: action.payload.total
            };
        default:
            return state;
    }
}

