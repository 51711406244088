import "./GatherForm.css";
import { Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { useState, useEffect } from "react";
import { formatDate } from "helpers";
import { getVariableListAction } from "actions/variableActions";
import { useSelector, useDispatch } from "react-redux";
import { variableSelector } from "selectors/variableSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { updateVoterAction } from "actions/voterActions";
import { getMunicipalityListAction } from "actions/municipalityActions";
import { getPrecinctListAction } from "actions/precinctActions";
import { getBarangayListAction } from "actions/barangayActions";
import { getProvinceListAction } from "actions/provinceActions";
import { municipalitySelector } from "selectors/municipalitySelector";
import { precinctSelector } from "selectors/precinctSelector";
import { barangaySelector } from "selectors/barangaySelector";
import { provinceSelector } from "selectors/provinceSelector";

function GatherForm({voter, onSubmit, onDiscard}) {
    const [formData, setFormData] = useState({...voter, voter_responses: JSON.parse(voter.voter_responses[0]?.response || '{}')});
    const [answers, setAnswers] = useState(voter.voter_responses || []);
    const { variables } = useSelector(variableSelector);
    const { municipalities } = useSelector(municipalitySelector);
    const { precincts } = useSelector(precinctSelector);
    const { barangays } = useSelector(barangaySelector);
    const { provinces } = useSelector(provinceSelector);
    const dispatch = useDispatch();
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        dispatch(getVariableListAction());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getMunicipalityListAction());
        dispatch(getPrecinctListAction());
        dispatch(getBarangayListAction());
        dispatch(getProvinceListAction());
    }, [dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateVoterAction(voter.voter_id, formData));
        onSubmit();
    }

    const handleDiscard = (e) => {
        onDiscard();
    }

    const handleQuestionAnswered = (e) => {
        setFormData((prev) => ({
            ...prev,
            voter_responses: {
                ...prev.voter_responses,
                [e.target.name]: e.target.value
            }
        }));

        setAnswers((prev) => [{
            response: JSON.stringify({
                ...JSON.parse(prev[0]?.response || '{}'),
                [e.target.name]: e.target.value
            }),
            ...prev
        }]);
    }

    const getCheckedOption = (variableName) => {        
        return JSON.parse(answers[0]?.response || '{}')?.[variableName];
    }

    const getValidOptions = (variable) => {
        return variable.variable_options.filter((option) => {
            console.log(option, formData)
            if (option.municity === null) return (option.is_active && option.province === formData.province)
            return option.is_active && option.province === formData.province && option.municity === formData.municity      
        });
    }

    const getValidVariables = () => {
        return variables.filter((variable) => (getValidOptions(variable).length > 0 || variable.type === 'STRING'));
    }

    return (
        <div id="gather-form">
            <Container>
                <Form onSubmit={handleSubmit}>     
                    <Stack gap={2}>                
                        <div className="d-flex justify-content-between">
                            <div>
                                <h4>Personal Information</h4>                                
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button variant="danger" className="me-2" onClick={handleDiscard}>
                                    <FontAwesomeIcon icon={faTimes} className="me-2"/>
                                    Discard
                                </Button>
                                <Button variant="success"type="submit">
                                    <FontAwesomeIcon icon={faSave} className="me-2"/>
                                    Save
                                </Button>
                            </div>
                        </div>   
                        <hr />
                        <Row>                        
                        <Col lg={2}>
                            <Form.Label>Name</Form.Label>
                        </Col>
                        <Col lg={10}>
                            <Form.Control type="text" name="name" defaultValue={formData.name} onBlur={handleChange} />
                        </Col>                        
                    </Row>
                    <Row>
                        <Col lg={2}>
                            <Form.Label>Sex</Form.Label>
                        </Col>
                        <Col lg={4}>
                            <Form.Control type="text" name="sex" defaultValue={formData.sex} onBlur={handleChange} />
                        </Col>
                        <Col lg={2}>
                            <Form.Label>Birthdate</Form.Label>
                        </Col>
                        <Col lg={4}>    
                            <Form.Control type="date" name="birthdate" defaultValue={formatDate(formData.birthdate)} onBlur={handleChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2}>
                            <Form.Label>Address</Form.Label>
                        </Col>
                        <Col lg={10}>    
                            <Form.Control type="text" name="address" defaultValue={formData.address} onBlur={handleChange} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2}>
                            <Form.Label>Precinct</Form.Label>
                        </Col>
                        <Col lg={10}>    
                            <Form.Select name="precinct" value={formData.precinct} onChange={handleChange}>
                                <option value="">Select Precinct</option>
                                {
                                    precincts.map((precinct) => <option key={precinct} value={precinct}>{precinct}</option>)
                                }
                            </Form.Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={2}>
                            <Form.Label>Barangay</Form.Label>
                        </Col>
                        <Col lg={10}>    
                            <Form.Select name="barangay" value={formData.barangay} onChange={handleChange}>
                                <option value="">Select Barangay</option>
                                {
                                    barangays.map((barangay) => <option key={barangay} value={barangay}>{barangay}</option>)
                                }
                            </Form.Select>
                        </Col>
                    </Row>                  
                    <Row>
                        <Col lg={2}>
                            <Form.Label>Municipality/City</Form.Label>
                        </Col>
                        <Col lg={10}>    
                            <Form.Select name="municipality" value={formData.municity} onChange={handleChange}>
                                <option value="">Select Municipality/City</option>
                                {
                                    municipalities.map((municipality) => <option key={municipality} value={municipality}>{municipality}</option>)
                                }
                            </Form.Select>
                        </Col>
                    </Row>  
                    <Row>
                        <Col lg={2}>
                            <Form.Label>Province</Form.Label>
                        </Col>
                        <Col lg={10}>    
                            <Form.Select name="province" value={formData.province} onChange={handleChange}>
                                <option value="">Select Province</option>
                                {
                                    provinces.map((province) => <option key={province} value={province}>{province}</option>)
                                }
                            </Form.Select>
                        </Col>
                    </Row>
                    <hr />
                    <h4>Questions</h4>
                    {
                        getValidVariables().map((variable) => (
                            <div key={variable.id} className="question-container">
                                <h5>{variable.question || variable.name}</h5>
                                {
                                    variable.type === 'STRING' ? (
                                        <Form.Control type="text" name={variable.name} defaultValue={getCheckedOption(variable.name)} onChange={handleQuestionAnswered} />
                                    ) : (
                                        getValidOptions(variable).map((option) => (
                                            <Form.Check 
                                                        name={variable.name} 
                                                        type="radio" 
                                                        label={option.question || option.name} 
                                                        value={option.name} 
                                                        checked={getCheckedOption(variable.name) === option.name} 
                                                    onChange={handleQuestionAnswered} 
                                            />
                                        ))
                                    )
                                }
                            </div>
                        ))
                    } 
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button variant="danger" className="me-2" onClick={handleDiscard}>
                            <FontAwesomeIcon icon={faTimes} className="me-2"/>
                            Discard
                        </Button>
                        <Button variant="success"type="submit">
                            <FontAwesomeIcon icon={faSave} className="me-2"/>
                            Save
                        </Button>
                    </div>
                    </Stack>
                </Form>
            </Container>
        </div>
    )
}

export default GatherForm;