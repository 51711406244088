import { Accordion, Nav } from 'react-bootstrap';
import { NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getModules } from 'helpers';
import { useEffect, useState } from 'react';
import './Sidebar.css';
import { faChartLine, faSearch, faCalendarAlt, faCogs, faMoneyBillWave, faComments, faCheckCircle, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { toggleSidebarAction } from 'actions/sidebarActions';

function Sidebar() {
  const dispatch = useDispatch();
    const [modules, setModules] = useState([]);

    const moduleConfig = {
      "Analyze": {
        "name": "Analyze",
        "path": "/cms/analyze",
        "icon": faChartLine,
        "items": [
          {
            "name": "Generate Graphs",
            "path": "/cms/analyze"
          },
          {
            "name": "Generate Heat Maps",
            "path": "/cms/analyze"
          }
        ]
      },
      "Gather": {
        "name": "Gather",
        "path": "/cms/gather",
        "icon": faSearch,
        "items": [
          {
            "name": "Find Voter",
            "path": "/cms/gather"
          }
        ]
      },
      "Plan": {
        "name": "Plan",
        "path": "/cms/plan",
        "icon": faCalendarAlt,
        "items": []
      },
      "Manage": {
        "name": "Manage",
        "path": "/cms/manage",
        "icon": faCogs,
        "items": [
          {
            "name": "Manage Variables",
            "path": "/cms/manage/variables"
          },
          {
            "name": "Manage Voters",
            "path": "/cms/manage/voters"
          },
          {
            "name": "Manage Users",
            "path": "/cms/manage/users"
          },
          {
            "name": "Manage Roles",
            "path": "/cms/manage/roles"
          }
        ]
      },
      "Pay": {
        "name": "Pay",
        "path": "/cms/pay",
        "icon": faMoneyBillWave,
        "items": []
      },
      "Communicate": {
        "name": "Communicate",
        "path": "/cms/communicate",
        "icon": faComments,
        "items": []
      },
      "Validate": {
        "name": "Validate",
        "path": "/cms/validate",
        "icon": faCheckCircle,
        "items": []
      },
      "Share": {
        "name": "Share",
        "path": "/cms/share",
        "icon": faShareAlt,
        "items": []
      }
    };

    useEffect(() => {
      const accessModules = getModules(localStorage.getItem("bitValue"));
      setModules(accessModules);
    }, []);

    const handleClick = () => {
      if (window.innerWidth < 768) {
        dispatch(toggleSidebarAction());
      }
    };

    return(
      <div className="sidebar sidebar-sticky">
        <Accordion defaultActiveKey={0}>
          {modules.map((module, index) => {
            return <Accordion.Item key={index} eventKey={index}>              
              <Accordion.Header>
                <FontAwesomeIcon icon={moduleConfig[module].icon} className="me-2" />
                <span className="sidebar-item-name">{moduleConfig[module].name}</span>
              </Accordion.Header>
              <Accordion.Body>
                <Nav>
                  {moduleConfig[module].items.map((item, itemIndex) => (
                    <NavLink key={itemIndex} to={item.path} className="nav-link" onClick={handleClick}>                       
                      {item.name}
                    </NavLink>
                  ))}
                </Nav>
              </Accordion.Body>
            </Accordion.Item>
          })}
        </Accordion>
      </div>
    );
};

export default Sidebar;